<template>
  <div class="Search">
    <!-- 文章列表 -->
    <div class="ArticleList">
      <div
        class="ArticleItem"
        v-for="(item, index) in ArticleList"
        :key="index"
      >
        <router-link
          v-if="item.thumb != ''"
          class="Image"
          to=""
          @click="Details(item.articleId, item.articleTitle)"
        >
          <img :src="'https://api.lphadmin.cn/uploads/' + item.thumb" alt="" />
        </router-link>
        <h2 class="Title">
          <router-link
            to=""
            @click="Details(item.articleId, item.articleTitle)"
          >
            {{
              item.articleTitle.substr(
                0,
                item.articleTitle.indexOf(formArticle.articleTitle)
              )
            }}
            <span style="color: red">{{ formArticle.articleTitle }}</span>
            {{
              item.articleTitle.substr(
                item.articleTitle.indexOf(formArticle.articleTitle) +
                  formArticle.articleTitle.length
              )
            }}
          </router-link>
        </h2>
        <div class="Digest">{{ item.articleDigest }}</div>
        <div class="Details">
          <p>
            <span
              v-if="item.Sticky === 1"
              class="icode"
              style="
                color: rgb(255, 114, 66);
                display: flex;
                align-items: center;
              "
              ><i class="iconfont" style="margin-right: 4px">&#xe890;</i>
              置顶</span
            >
            <span class="icode"
              ><i class="iconfont">&#xe600;</i> {{ item.creationTime }}</span
            >
            <span class="icode"
              ><i class="iconfont">&#xe6a7;</i> {{ item.articleClassify }}</span
            >
            <span class="icode">
              <a v-for="(items, index) in item.articleLable" :key="index">
                <span v-for="(i, index) in labelList" :key="index">
                  <el-tag
                    v-if="items === i.labelId"
                    :style="{
                      color: i.labelColor,
                      backgroundColor: i.labelBgc,
                      borderColor: i.labelBgc,
                    }"
                    @click="labelClick(i)"
                    >{{ i.labelName }}</el-tag
                  >
                </span>
              </a>
            </span>
          </p>
          <a @click="Details(item.articleId, item.articleTitle)">阅读全文</a>
        </div>
      </div>
    </div>
    <div class="paging">
      <span v-if="formArticle.page != 1" @click="nextPage('page')">上一页</span>
      <span
        v-for="item in pages"
        :key="item"
        @click="breaks(item)"
        :class="{
          active: formArticle.page === item,
        }"
        >{{ item }}</span
      >
      <span v-if="formArticle.page != pages" @click="nextPage('next')"
        >下一页</span
      >
    </div>
  </div>
</template>

<script setup name="Search">
import { ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { getArticle } from "@/api/article";
import { getLabel } from "@/api/label";

const router = useRouter();
// 文章搜索条件
const formArticle = ref({
  limit: 5,
  page: 1,
  articleTitle: "", // 名称
  articleLable: "", // 标签 [726719]
});

watch(router.currentRoute, (newValue, oldValue) => {
  getSearchList(newValue.query);
});

// 文章总数
const articleNum = ref(0);
// 文章列表
const ArticleList = ref([]);
const getArticleList = () => {
  let obj = { ...formArticle.value };
  for (let key in obj) {
    if (obj[key] === "") {
      delete obj[key];
    }
  }
  getArticle(obj).then((res) => {
    if (res.code === 200) {
      ArticleList.value = [];
      res.data.data.forEach((v) => {
        v.articleLable = JSON.parse(v.articleLable);
        ArticleList.value.push(v);
      });
      articleNum.value = res.data.total;
      pageImgNum();
    }
  });
};

// 获取标签
const labelList = ref([]);
const getLabels = () => {
  getLabel().then((res) => {
    if (res.code === 200) {
      labelList.value = res.data.data;
    }
  });
};

// 跳转详情
const Details = (id, title) => {
  let obj = {
    id,
    title,
  };
  router.push({
    path: "/details",
    query: {
      id,
    },
  });
};
// label点击
const labelClick = (i) => {
  console.log(i);
  router.push({
    path: "/search",
    query: {
      type: i.labelId,
      typeLable: i.labelName,
    },
  });
};

const pages = ref();
// 分页数
const pageImgNum = () => {
  pages.value = Math.ceil(articleNum.value / 5);
};

const getSearchList = (query) => {
  console.log(query.title);
  if (query.title != undefined) {
    formArticle.value.articleLable = [];
    formArticle.value.articleTitle = query.title;
    document.title = query.title + " - 小情绪";
  } else {
    formArticle.value.articleTitle = "";
    formArticle.value.articleLable = query.type;
    document.title = query.typeLable + " - 小情绪";
  }
  getArticleList();
};

// 分页按钮
const breaks = (num) => {
  formArticle.value.page = num;
  getArticleList();
};
const nextPage = (type) => {
  if (type === "page") {
    formArticle.value.page = formArticle.value.page - 1;
  } else {
    formArticle.value.page = formArticle.value.page + 1;
  }
  getArticleList();
};

onMounted(() => {
  getLabels();
  getSearchList(router.currentRoute.value.query);
});
</script>

<style scoped lang="scss">
.Search {
  width: 100%;
}
.ArticleList {
  .ArticleItem {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 10px;
    box-sizing: border-box;
    font-size: 16px;
    .Image {
      display: flex;
      justify-content: center;
      border-radius: 4px;
      width: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 320px;
        transition: transform 3s ease-out, opacity 0.5s ease-out;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    .Title {
      padding: 15px 0 10px 10px;
      a {
        text-decoration: none;
        font-size: 22px;
        color: #333;
      }
    }
    .Digest {
      color: #333;
      opacity: 0.8;
      font-size: 18px;
      padding: 0 0 0 10px;
    }
    .Details {
      padding: 20px 10px 10px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      opacity: 0.8;
      font-size: 16px;
      p {
        display: flex;
        align-items: center;
        .icode {
          margin-right: 10px;
          a {
            margin-right: 5px;
            cursor: pointer;
          }
        }
      }
      a {
        cursor: pointer;
        text-decoration: none;
        font-weight: 700;
        color: #409eff;
        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}
.paging {
  margin-top: 20px;
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
  background-color: #fff;
  text-align: center;
  span {
    padding: 0 10px;
    margin: 0 5px;
    cursor: pointer;
    &:hover {
      border-bottom: 1px solid #e8e8e8;
    }
  }
  .active {
    color: #409eff;
  }
}
</style>
